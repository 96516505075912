import { useGetClient } from "../utils/HttpCommon";
import { Media } from "../views/Media/MediaInterfaces";
import {
  LicenseGroup,
  Licensor,
  NewPermissionPattern,
  Permission,
  PermissionOption,
  PermissionPattern,
} from "../views/Permission/PermissionInterfaces";

export interface LicenseGroupListRequestParameters {
  bible: string | undefined;
}

export interface PermissionOptionsResponse {
  data: PermissionOption[];
}

export interface PermissionResponse {
  data: Permission;
}

export interface LicenseGroupListResponse {
  data: LicenseGroup[];
}

export interface PermissionPatternListResponse {
  data: PermissionPattern[];
}

export interface PermissionPatternResponse {
  data: PermissionPatternDetail;
}

export interface PermissionPatternDetail {
  id: number;
  name?: string;
  permissionOptions: PermissionOption[];
}

export interface LicensorListResponse {
  data: Licensor[];
}

export interface LicensorResponse {
  data: Licensor;
}

const usePermissionService = () => {
  const { http } = useGetClient();

  const getPermissionOptions = async (): Promise<PermissionOption[]> => {
    const response = await http.get<PermissionOptionsResponse>(
      `/agreement/permissions`,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData.sort((a, b) => a.displayOrder - b.displayOrder);
  };

  const getLicenseGroupList = async (
    params: LicenseGroupListRequestParameters,
  ): Promise<LicenseGroup[]> => {
    const response = await http.get<LicenseGroupListResponse>(
      `/agreement/license?bibleId=${params.bible}`,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const createLicenseGroup = async (media: Media): Promise<string> => {
    const response = await http.post<string>(
      `/agreement/license-media/${media.id}`,
      {
        data: media.newLicenseGroup,
      },
    );

    const { data } = response;

    return data;
  };

  const getAgreement = async (licenseGroupId: number): Promise<Permission> => {
    const response = await http.get<PermissionResponse>(
      `/agreement/license/${licenseGroupId}`,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };
  const updateLicenseGroup = async (
    permission: Permission,
  ): Promise<Permission> => {
    const response = await http.patch<Permission>(
      `/agreement/license/${permission.id}`,
      {
        copyright: permission.copyright,
        copyrightCombined: permission.copyrightCombined,
        permissionOptions: [...permission.accessGroups],
      },
    );

    const { data } = response;

    return data;
  };

  const getPermissionsPatternList = async (): Promise<PermissionPattern[]> => {
    const response = await http.get<PermissionPatternListResponse>(
      `/agreement/permission/patterns`,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const getPermissionPattern = async (
    permissionPatternId: number,
  ): Promise<PermissionPatternDetail> => {
    const response = await http.get<PermissionPatternResponse>(
      `/agreement/permission/pattern/${permissionPatternId}`,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const createPermissionPattern = async (
    params: NewPermissionPattern,
  ): Promise<PermissionPatternDetail> => {
    const response = await http.post<PermissionPatternResponse>(
      `/agreement/permission/pattern`,
      params,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const editPermissionPattern = async (
    params: NewPermissionPattern,
  ): Promise<PermissionPatternDetail> => {
    const response = await http.patch<PermissionPatternResponse>(
      `/agreement/permission/pattern/${params.id}`,
      {
        name: params.editedOriginalName,
        permissionOptions: [...params.permissionOptions],
      },
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const applyPermissionPattern = async (
    permission: Permission,
  ): Promise<Permission> => {
    const response = await http.patch<Permission>(
      `/agreement/permission/${permission.id}`,
      { newPermissionPatternId: permission.permissionPattern.id },
    );

    const { data } = response;

    return data;
  };

  const getLicensorList = async (): Promise<Licensor[]> => {
    const response =
      await http.get<LicensorListResponse>(`/agreement/licensor`);

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const createLicensor = async (licensor: FormData): Promise<Licensor> => {
    const response = await http.post<LicensorResponse>(
      `/agreement/licensor`,
      licensor,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const updateLicensorWithFile = async (
    licensor: FormData,
  ): Promise<Permission> => {
    const response = await http.put<Permission>(
      `/agreement/licensor/${licensor.get("id")}`,
      licensor,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    const { data } = response;

    return data;
  };

  const updateLicensor = async (licensor: Licensor): Promise<Permission> => {
    const response = await http.put<Permission>(
      `/agreement/licensor/${licensor.id}`,
      {
        name: licensor.name,
        logo: licensor.logo,
      },
    );

    const { data } = response;

    return data;
  };

  const associateLicensor = async (
    permissionId: number,
    licensor: Licensor,
  ): Promise<Permission> => {
    const response = await http.post<Permission>(
      `/agreement/license/${permissionId}/licensor`,
      {
        licensorId: licensor.id,
      },
    );

    const { data } = response;

    return data;
  };

  const disassociateLicensor = async (
    permissionId: number,
    licensorId: number,
  ): Promise<Permission> => {
    const response = await http.delete<Permission>(
      `/agreement/license/${permissionId}/licensor/${licensorId}`,
    );

    const { data } = response;

    return data;
  };

  const deleteLicensor = async (licensorId: number): Promise<Permission> => {
    const response = await http.delete<Permission>(
      `/agreement/licensor/${licensorId}`,
    );

    const { data } = response;

    return data;
  };

  return {
    getPermissionOptions,
    getLicenseGroupList,
    createLicenseGroup,
    getAgreement,
    getPermissionsPatternList,
    getPermissionPattern,
    createPermissionPattern,
    editPermissionPattern,
    applyPermissionPattern,
    getLicensorList,
    createLicensor,
    updateLicensor,
    updateLicensorWithFile,
    associateLicensor,
    disassociateLicensor,
    deleteLicensor,
    updateLicenseGroup,
  };
};

export default usePermissionService;
