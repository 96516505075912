import { RefetchOptions, QueryObserverResult } from "@tanstack/react-query";
import { Media } from "../Media/MediaInterfaces";
import { Permission as PermissionInterface } from "./PermissionInterfaces";
import { AlertMessage } from "../Home/HomeInterfaces";

export const PERMISSION_CUSTOM_NAME = "Custom";
export const PERMISSION_CUSTOM_ID = 100;

export interface PermissionProps {
  selectedMedia: Media;
  setAlertList: React.Dispatch<React.SetStateAction<AlertMessage[]>>;
  permissionData: PermissionInterface | undefined;
  getPermission: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<PermissionInterface, Error>>;
  showSensitiveFields: () => boolean;
  selectedLanguageId: string | undefined;
  selectedBibleId: string | undefined;
  licenseGroups: LicenseGroup[];
}

export interface Permission {
  id: number;
  name: string;
  copyright: string;
  copyrightCombined: boolean;
  licensors: Licensor[];
  permissionPattern: PermissionPattern;
  accessGroups: PermissionOption[];
}

export interface Licensor {
  id: number;
  name: string;
  logo?: string;
  editMode?: string;
}

export interface PermissionOption {
  id: number;
  name: string;
  modeId?: number;
  description?: string;
  displayOrder: number;
}

export interface PermissionPattern {
  id: number;
  name: string;
  associatedMediaRecords: number;
}

export interface NewPermissionPattern {
  id?: number;
  name: string;
  editedOriginalName?: string;
  permissionOptions: PermissionOption[];
}

export interface LicenseGroup {
  id: number;
  name: string;
}

export interface ConfirmationDialog {
  open: boolean;
  title: string;
  text: string;
  confirmCallback: () => void;
  cancelCallback: () => void;
}

export interface ManageLicensorsDialogProps {
  open: boolean;
  onClose: () => void;
  setAlertList: React.Dispatch<React.SetStateAction<AlertMessage[]>>;
  licensorList: Licensor[];
}
export interface PermissionTableProps {
  allPermissionOptions: PermissionOption[];
  permissionOptionsSelected: PermissionOption[];
  disabled: boolean;
  handleCheckboxChange: (
    modeId: number,
  ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  mediaTypeMode?: number;
  belongPermissionPattern: boolean;
}

export interface PermissionTableContainerProps {
  isCustomizeEnable: boolean;
  hasCustomLicensePermission: boolean;
  creatingPattern: boolean;
  editingPattern: boolean;
  permissionOptionsData: PermissionOption[];
  permissionPatternDetail: {
    id?: number;
    permissionOptions?: PermissionOption[];
  };
  newPattern?: {
    permissionOptions?: PermissionOption[];
  };
  selectedMedia: {
    licenseGroupId?: number;
    mediaModeType?: number;
  };
  customLicensePermission?: {
    accessGroups?: PermissionOption[];
  };
  permissionData?: {
    accessGroups?: PermissionOption[];
  };
  updateCheckbox: (
    modeId: number,
  ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  updateCustomCheckbox: (
    modeId: number,
  ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  editingSelectedPattern?: boolean;
}
